import { createRouter, createWebHashHistory } from "vue-router";
import Customer from "@/router/Customer";
import MonthlySales from "@/router/Monthly-Sales";
import Sales from "@/router/Sales";
import Visit from "@/router/Visit";
import WeeklyTarget from "@/router/Weekly-Target";
import Manager from "@/router/Manager";
const routes = [
  {
    path: "/",
    name: "Mobile",
    component: () => import("@/views/Mobile"),
    children: [
      {
        path: "/visit",
        name: "Home",
        component: () => import("@/views/Visit/List"),
      },
      {
        path: "/pengaturan",
        name: "Pengaturan",
        component: () => import("@/views/Pengaturan/Pengaturan"),
      },
      {
        path: "/aplikasi",
        name: "Aplikasi",
        component: () => import("@/views/Pengaturan/Application"),
      },
      {
        path: "/kota",
        name: "Kota",
        component: () => import("@/views/Pengaturan/Kota"),
      },
      {
        path: "/notification",
        name: "Notification",
        component: () => import("@/views/Notification/List"),
      },
      ...Customer,
      ...MonthlySales,
      ...Sales,
      ...Visit,
      ...WeeklyTarget,
      ...Manager,
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
