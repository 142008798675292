<template>
  <q-layout view="hHh lpR fFf">
    <q-header>
      <q-toolbar v-if="!hiddenComp" class="bg-primary">
        <q-btn flat stretch icon="menu" @click="toggleLeftDrawer"></q-btn>
        <q-toolbar-title>{{ route.name }}</q-toolbar-title>
        <q-btn flat dense color="white" icon="notifications" to="/notification">
          <q-badge v-if="countNotif > 0" color="red" floating>{{
            countNotif
          }}</q-badge>
        </q-btn>
      </q-toolbar>
    </q-header>
    <q-drawer
      v-if="!hiddenComp"
      v-model="leftDrawerOpen"
      overlay
      :width="250"
      bordered
      class="bg-white column justify-between"
    >
      <q-list v-if="isReady" class="column q-gutter-sm">
        <img
          class="q-px-md"
          style="padding-top: 5.2%; width:100%"
          :src="`../img/fortebond.png`"
          spinner-color="white"
        />
        <div class="my-font text-bold text-center q-mt-sm text-body1">
          Sales App
        </div>
        <q-separator></q-separator>
        <q-item class="row items-center">
          <q-item-section top avatar>
            <q-avatar color="grey" text-color="white" icon="person" />
          </q-item-section>

          <q-item-section>
            <q-item-label class="text-bold text-h6">{{
              currentUser.username
            }}</q-item-label>
            <q-btn
              flat
              dense
              align="left"
              label="LOGOUT"
              color="negative"
              @click="logout"
            ></q-btn>
          </q-item-section>
        </q-item>
        <q-separator></q-separator>
        <q-item dense clickable v-ripple v-can="['SALES', 'ADMIN']" to="/visit">
          Visit
        </q-item>
        <q-item dense clickable v-ripple v-can="['ADMIN']" to="/kota">
          List Kota
        </q-item>
        <q-item dense clickable v-ripple v-can="['ADMIN']" to="/aplikasi">
          List Aplikasi
        </q-item>
        <q-item
          dense
          clickable
          v-ripple
          v-can="['SALES']"
          :to="`/visit/history/${currentUser.id}`"
        >
          Visit History
        </q-item>
        <q-item
          dense
          clickable
          v-ripple
          v-can="['SALES']"
          to="/customer/create"
        >
          New Customer
        </q-item>
        <q-item
          dense
          clickable
          v-ripple
          v-can="['SALES']"
          :to="`/customer/list/${currentUser.id}`"
        >
          List Customer
        </q-item>
        <q-item
          dense
          clickable
          v-ripple
          v-can="['ADMIN', 'MANAGER']"
          to="/sales/list"
        >
          List Sales
        </q-item>
        <q-item
          dense
          clickable
          v-ripple
          v-can="['ADMIN']"
          to="/weekly-target/list"
        >
          Weekly Target
        </q-item>
        <q-item
          dense
          clickable
          v-ripple
          v-can="['ADMIN']"
          to="/monthly-sales/list"
        >
          Monthly Sales
        </q-item>
        <q-item
          dense
          clickable
          v-can="['MANAGER', 'SALES']"
          v-ripple
          to="/manager/growth"
        >
          Sales Growth
        </q-item>
        <q-item
          dense
          clickable
          v-ripple
          v-can="['MANAGER']"
          to="/manager/statistic"
        >
          Statistic
        </q-item>
        <q-item
          dense
          clickable
          v-ripple
          v-can="['MANAGER']"
          to="/manager/customer-growth"
        >
          Costumer Growth
        </q-item>
      </q-list>
      <div>
        <q-separator></q-separator>
        <q-item dense clickable v-ripple to="/pengaturan">
          <q-item-section>
            <q-item-label class="row items-center"
              ><q-icon
                class="q-mr-sm text-grey-8"
                name="settings"
                style="font-size: 1.6em;"
              />
              PENGATURAN
            </q-item-label>
          </q-item-section>
        </q-item>
      </div>
    </q-drawer>
    <q-page-container>
      <router-view v-if="!hiddenSplash && isReady" />
    </q-page-container>
    <div
      v-if="hiddenSplash"
      id="theSplash"
      class="splash row justify-center"
      @click="hiddenSplash = false"
    >
      <div
        class="q-my-xl column justify-center items-center q-gutter-md"
        style="max-width:700px"
      >
        <img
          :src="`../img/fortebond.png`"
          style="padding-top: 5.2%; width:100%"
          class="q-px-lg"
          spinner-color="white"
        />
        <div class="column text-left">
          <a class="text-h6"><span class="text-red">F</span>ast</a>
          <a class="text-h6"><span class="text-red">O</span>pen</a>
          <a class="text-h6"><span class="text-red">R</span>eligious</a>
          <a class="text-h6"><span class="text-red">T</span>rust</a>
          <a class="text-h6"><span class="text-red">E</span>fficient</a>
        </div>
      </div>
    </div>
  </q-layout>
</template>

<script>
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { useQuasar } from "quasar";
import { computed, onMounted, reactive, ref, watch, inject } from "vue";
import logo from "./assets/splash.png";
import VueSplash from "vue-splash";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const $http = inject("$http");
    const $q = useQuasar();
    // $http.interceptors.request.use(
    //   (cfg) => {
    //     if (!cfg.url.includes("notification/count")) {
    //       $q.loading.show({ message: "Mohon tunggu sebentar" });
    //     }

    //     return cfg;
    //   },
    //   (err) => {
    //     $q.loading.hide();
    //   }
    // );

    let curUser = computed(() => store.state.user);

    // $http.interceptors.response.use(
    //   (resp) => {
    //     $q.loading.hide();
    //     return resp;
    //   },
    //   (err) => {
    //     $q.loading.hide();
    //   }
    // );

    let leftDrawerOpen = ref(false);

    let yourLogo = computed(() => {
      return logo;
    });

    const currentUser = computed(() => store.state.user);

    let logout = () => {
      localStorage.clear();
      window.clearInterval(idInterval);
      store.commit("CLEAR_ROLE");
      router.push("/login");
    };

    let some = ref(1);
    let isReady = ref(false);
    let hiddenComp = ref(false);
    let hiddenSplash = ref(false);

    let countNotif = ref(0);
    let idInterval = null;

    let getNotif = async () => {
      let resp = await $http.get(`/notification/count/${curUser.value.id}`);
      countNotif.value = resp.data?.banyak;
    };

    let checklogin = async () => {
      let token = localStorage.getItem("token");
      if (token) {
        try {
          await store.dispatch("ACC_ROLE", {
            token: localStorage.getItem("token"),
          });
          idInterval = window.setInterval(async () => {
            await getNotif();
          }, 5000);

          if (route.name == "Mobile") {
            if (store.state.user.is_manager == "1") {
              router.push("/manager/statistic");
            } else {
              router.push("/visit");
            }
          }
        } catch (err) {
          logout();
        }
      } else {
        logout();
      }
      isReady.value = true;
    };

    watch(route, async (to) => {
      if (
        to.name == "Home" ||
        to.name == "SalesList" ||
        to.name == "Statistic" ||
        to.name == "Login"
      ) {
        await checklogin();
      }

      if (to.name == "Login") {
        hiddenComp.value = true;
        hiddenSplash.value = true;
        splasher();
      } else {
        hiddenComp.value = false;
        hiddenSplash.value = false;
      }
    });

    let splasher = () => {
      const splash = document.getElementById("theSplash");
      setTimeout(() => {
        // setelah timeout splash hilang, dan z-index mundur
        // splash.classList.add("display-none");
        hiddenSplash.value = false;
      }, 5000);
    };

    onMounted(async () => {
      await checklogin();
      some.value = 2;
    });

    return {
      leftDrawerOpen,
      currentUser,
      checklogin,
      logout,
      router,
      route,
      store,
      some,
      isReady,
      yourLogo,
      VueSplash,
      hiddenComp,
      hiddenSplash,
      countNotif,

      toggleLeftDrawer() {
        leftDrawerOpen.value = !leftDrawerOpen.value;
      },
    };
  },
};
</script>

<style lang="scss">
@import "@/styles/quasar.scss";
.stickyTable {
  height: 300px;

  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th {
    background-color: $indigo-5;
  }
  thead tr th {
    position: sticky;
    z-index: 1;
  }
  thead tr:first-child th {
    top: 0;
  }
  &.q-table--loading thead tr:last-child th {
    top: 48px;
  }
}
.l-container {
  height: calc(100vh - 50px);
  width: 100vw;
}
.l-mobile-container {
  max-width: 700px;
  min-width: 300px;
  height: calc(100vh - 50px);
  margin: 0 auto;
}
.l-wrapper {
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.l-grow {
  flex-grow: 99;
}
.splash {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100vh;
  background: white;
  z-index: 200;
  color: black;
  text-align: center;

  opacity: 0;
  animation: fadeIn 5s ease-in-out forwards;
}

.splash.display-none {
  position: fixed;
  // display: none;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: black;
  z-index: -200;
  color: white;
  text-align: center;
  line-height: 90vh;
  transition: all 1s;
}

@keyframes fadeIn {
  // to {
  //   opacity: 1;
  // }
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade-in {
  opacity: 0;
  animation: fadeIn 3s ease-in-out forwards;
}

@font-face {
  font-family: customfont;
  src: url(./views/css/fonts/Monopola.ttf);
}

.my-font {
  font-family: "customfont";
}
</style>
