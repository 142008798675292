export default
  [
    {

      path: '/sales/list',
      name: 'SalesList',
      component: () => import('@/views/Sales/List')
    },
    {

      path: '/sales/create',
      name: 'SalesCreate',
      component: () => import('@/views/Sales/Create')
    }
  ]