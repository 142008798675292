import { createStore } from "vuex";
import axios from "axios";

let http = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});

export default createStore({
  state: {
    user: {
      id: 1,
    },
  },
  mutations: {
    SET_ROLE(state, data) {
      state.user = data;
    },
    CLEAR_ROLE(state) {
      state.user = {};
    },
  },
  actions: {
    async ACC_ROLE(state, data) {
      let resp = await http.post(`/user_data`, data);
      state.state.user = resp.data;
    },
  },
  modules: {},
  getters: {
    GET_ROLE(state) {
      return state.user;
    },
  },
});
