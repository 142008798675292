export default [
  {
    path: "/manager/statistic/:filter?",
    name: "Statistic",
    component: () => import("@/views/Manager/Statistic"),
  },
  {
    path: "/manager/statisticdetail/:filter",
    name: "StatisticDetail",
    component: () => import("@/views/Manager/StatisticDetail"),
  },
  {
    path: "/manager/growth",
    name: "SalesGrowth",
    component: () => import("@/views/Manager/Growth"),
  },
  {
    path: "/manager/customer-growth",
    name: "CustomerGrowth",
    component: () => import("@/views/Manager/CustomerGrowth"),
  },
];
