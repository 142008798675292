import { createApp, computed } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import quasarUserOptions from "./quasar-user-options";
import axios from "axios";
import Camera from "simple-vue-camera";
import VueSplash from "vue-splash";
import money from "v-money3";
import VueNumerals from "vue-numerals";
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';;

const app = createApp(App);
app
  .component("camera", Camera)
  .component("splash", VueSplash)
  .use(Quasar, {
    plugins: {
      Notify,
      Loading,
    },
    config: {
      loading: {},
    },
    quasarUserOptions,
  })
  .use(store)
  .use(router)
  .use(money)
  .use(VueNumerals, {
    locale: "id",
  });

app.provide(
  "$http",
  axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
  })
);

app.directive("can", {
  beforeMount(el, binding, vnode) {
    // vuex diisi saat sukses login
    let role = binding.instance.store;

    if (binding.value.includes("SALES") && role.state.user.is_sales == "1") {
      return;
    } else if (
      binding.value.includes("ADMIN") &&
      role.state.user.is_admin == "1"
    ) {
      return;
    } else if (
      binding.value.includes("MANAGER") &&
      role.state.user.is_manager == "1"
    ) {
      return;
    } else {
      el.style.display = "none";
    }
  },
});

app.mount("#app");
