export default [
  {
    path: "/weekly-target/list",
    name: "WeeklyTargetSalesList",
    component: () => import("@/views/Weekly-Target/List"),
  },
  {
    path: "/weekly-target/create/:id",
    name: "WeeklyTargetCreate",
    component: () => import("@/views/Weekly-Target/Create"),
  },
];
