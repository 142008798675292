export default [
  {
    path: "/visit/list",
    name: "VisitList",
    component: () => import("@/views/Visit/List"),
  },
  {
    path: "/visit/create",
    name: "VisitCreate",
    component: () => import("@/views/Visit/Create"),
  },
  {
    path: "/visit/history/:id/:filter?",
    name: "VisitHistory",
    component: () => import("@/views/Visit/History"),
  },
  {
    path: "/visit/sales-summary/:id",
    name: "VisitSalesSummary",
    component: () => import("@/views/Visit/Sales-Summary"),
  },
  {
    path: "/visit/admin-summary/:id",
    name: "VisitAdminSummary",
    component: () => import("@/views/Visit/Admin-Summary"),
  },

  {
    path: "/visit/camera/:id",
    name: "VisitCamera",
    component: () => import("@/views/Visit/Camera"),
  },
];
