export default [
  {
    path: "/customer/list/:id/:filter?",
    name: "CustomerList",
    component: () => import("@/views/Customer/List"),
  },
  {
    path: "/customer/create",
    name: "CustomerCreate",
    component: () => import("@/views/Customer/Create"),
  },
  {
    path: "/customer/edit/:id",
    name: "CustomerEdit",
    component: () => import("@/views/Customer/Edit"),
  },
  {
    path: "/customer/history/:id/:filter?",
    name: "CustomerHistory",
    component: () => import("@/views/Customer/History"),
  },
  {
    path: "/customer/changetype/:id",
    name: "ChangeType",
    component: () => import("@/views/Customer/ChangeType"),
  },
];
