export default
  [
    {

      path: '/monthly-sales/list',
      name: 'MonthlySalesList',
      component: () => import('@/views/Monthly-Sales/List')
    },
    {

      path: '/monthly-sales/create',
      name: 'MonthlySalesCreate',
      component: () => import('@/views/Monthly-Sales/Create')
    },
    {

      path:'/monthly-sales/detail/:year/:month',
      name: 'MonthlySalesDetail',
      component: () => import('@/views/Monthly-Sales/Detail')
    }
  ]